import { FC, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { sendAnalyticEvent } from '@src/components/web-analytic';
import {
  CLIENT_ORDER_ROUTE,
  ClientCategory,
  PAYMENT_ERROR_ROUTE,
  PAYMENT_IN_PROGRESS_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
  Product,
  analyticEvents,
} from '@src/constants';
import {
  useAxiosInterceptors,
  useIsPrivateOffice,
  usePhoneFromQuery,
} from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { getProductForAnalytic } from '@src/utils';

import { StyledAppContent } from './app-content.styles';

export const AppContent: FC = ({ children }) => {
  const {
    state: {
      stateUser: { selectedProduct, isPureSubscription },
    },
    dispatch,
  } = useContext(Store);
  useAxiosInterceptors();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const product = searchParams.get('product');
  const preset = searchParams.get('preset');
  const clientCategoryFromUrl = searchParams.get('clientCategory');
  const regionFiasIdFromUrl = searchParams.get('region');
  const { pathname } = useLocation();

  useIsPrivateOffice();

  usePhoneFromQuery();

  useEffect(() => {
    if (!!selectedProduct || !!product) {
      localStorage.setItem(
        'selectedProduct',
        selectedProduct ?? (product as string)
      );
    }
  }, [product, selectedProduct]);

  useEffect(() => {
    preset && dispatch({ type: UserActionTypes.SetPreset, payload: preset });
  }, [preset]);

  useEffect(() => {
    clientCategoryFromUrl &&
      dispatch({
        type: UserActionTypes.SetClientCategoryFromUrl,
        payload: clientCategoryFromUrl as ClientCategory,
      });
  }, [clientCategoryFromUrl]);

  useEffect(() => {
    regionFiasIdFromUrl &&
      dispatch({
        type: UserActionTypes.SetRegionFiasIdFromUrl,
        payload: regionFiasIdFromUrl,
      });
  }, [regionFiasIdFromUrl]);

  useEffect(() => {
    if (product) {
      dispatch({
        type: UserActionTypes.SetSelectedProduct,
        payload: product as Product,
      });

      dispatch({
        type: AuthActionTypes.SetAuthorizeRefRoute,
        payload: `/${product}`,
      });
    } else {
      if (
        pathname.includes(PAYMENT_SUCCESS_ROUTE) ||
        pathname.includes(PAYMENT_ERROR_ROUTE) ||
        pathname.includes(PAYMENT_IN_PROGRESS_ROUTE) ||
        pathname.includes(CLIENT_ORDER_ROUTE)
      ) {
        return;
      }
      if (!selectedProduct) {
        navigate('/');
      }
    }
  }, [product]);

  useEffect(() => {
    if (isPureSubscription) {
      navigate('/personal-info');
    }
  }, [isPureSubscription]);

  useEffect(() => {
    if (
      pathname.includes(PAYMENT_SUCCESS_ROUTE) ||
      pathname.includes(PAYMENT_ERROR_ROUTE)
    ) {
      return;
    }

    if (product) {
      sendAnalyticEvent(analyticEvents.webStart, {
        product_selected: 'y',
        product: getProductForAnalytic(product),
      });
    } else {
      sendAnalyticEvent(analyticEvents.webStart, {
        product_selected: 'n',
      });
    }
  }, []);

  return (
    <StyledAppContent hasPadding={!pathname.includes(CLIENT_ORDER_ROUTE)}>
      {children}
    </StyledAppContent>
  );
};
